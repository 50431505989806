<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        <form action="" class="box" @submit.prevent="submitForm">
        <header class="modal-card-head">
        <p class="modal-card-title">Add or Modify Expense Types</p>
        <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <section class="modal-card-body">
                <b-field label="Type *" label-for="expType.name">

                    <b-input custom-class="input is-danger" type="text" v-model="expType.name"></b-input>

                </b-field>

        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
        <button class="button" type="button" @click="closeDialog">Cancel</button>
        <span class='is-danger'></span>
        </footer>
    </form>

    </div>
    </div>
  </section>
</article>
</template>


<script>

import axios from 'axios'
import UserStore from "../../../../../store/UserStore"

export default {
    props: {
         canCancel: String, 
         isShowDialog: Boolean,
         expType: {},
         storeId: Number,
         id: Number,
         action: String,
    },

    data() {
        return {
            openOnFocus: true,
            keepFirst: true,
            clearable: false,
            data: [],
            date: new Date(),
            isFetching: false,
            page: 1,
            totalPages: 1,
            user: [],     
        }
    },

    methods: {

        fetchVendors() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/vendors'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.vendors = response.data.data
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        closeDialog() {
            this.vendor = {}
            this.vendorName = ""
            this.$emit('hide-dialog')
            this.email = ""
            this.role = ""
        },

        submitForm() {

            this.$emit('save-record')
            this.closeDialog()

        },

        alertMessage(message) {
            alert(message)
        }

    },

    computed: {

        isDisabled() {

            if (this.expType.name
                ) {
                    return false
                } else {
                    return true
                }

        },

        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },        

        filteredVendors() {
            return this.vendors.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.vendorName.toLowerCase()) >= 0
                )
            })
        },       

    },

    mounted() {
        this.user = this.getUser()
        this.fetchVendors()
    }

}
</script>

<style scoped>

/* .scrollable {
    max-height: 200vw;
    width: 400px;
    overflow: auto; 
} */


</style>