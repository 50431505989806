<template>
    <div class="panel has-text-weight-semibold" v-if="getActiveStore.role != 'STAFF'">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">EXPENSE TYPES</div>
            </div>
        </div>
        <hr>
        <b-table 
            :data='expenseTypes'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="name"
            defaultSortDirection='asc'
            >

            <template>

                <b-table-column field="name" label="Name" sortable sorticon searchable v-slot="props">
                    {{ props.row.name }} 
                </b-table-column>


                <!-- <b-table-column class="" v-slot="props">
                        <i class="button is-info" v-on:click="doAction('edit',props.row)" v-if="props.row.storeId != null">
                            <i class="fas fa-edit"></i>
                        </i>
                        <i v-else>Builtin</i>

                </b-table-column> -->

                <b-table-column class="is-2 has-text-right" field="amount" label="Edit" v-slot="props" sortable sorticon>
                    <b-button class="is-primary is-small" outlined rounded  v-on:click="doAction('edit',props.row)" v-if="props.row.storeId != null">
                        <i class="far fa-edit"></i>
                    </b-button>
                    <i v-else>Builtin</i>
                </b-table-column> 

            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

            <template slot="top-left">
                <a class="button is-primary" v-on:click="doAction('add')">
                    <i class="fas fa-money-check"><span class="btn-label-text"> New</span></i>
                </a>
            </template>

            <template slot="bottom-left">
                <a class="button is-primary" v-on:click="doAction('add')">
                   <i class="fas fa-money-check"><span class="btn-label-text"> New</span></i>
                </a>
            </template>
        </b-table>

        <expense-type-form
            :storeId="storeId"
            :expType="expTypeTemp"
            :action="action"
            :isShowDialog="isShowDialog"  
            @hide-dialog="toggleDialog"
            @save-record="saveRecord"
            @refresh-data="fetchRecords">        
        >
        </expense-type-form>


    </div>
</template>

<script>
import UserStore from "../../../../../store/UserStore"

import DataLoading from '../../../../../components/app/DataLoading.vue'

import axios from 'axios'

import ExpenseTypeForm from './ExpenseTypeForm'

export default {

    components: {
        DataLoading,
        ExpenseTypeForm,

    },

    metaInfo: {
        title: 'Store Expense Types'
    },

    data() {
        return {
            expenseTypes: [],
            expType: {},          
            expTypeTemp: {},
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            userId: 0,
            email: '',
            role: '',
            deleteAllowed: true,

        }
    },

    methods: {

        fetchRecords() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/expense_type'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        // this.expenseTypes = response.data.data

                        this.expenseTypes = response.data.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        // console.log("Sorted Expense Tyoes", this.expenseTypes)

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        doAction(action, expType) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.toggleDialog()
                    this.expType = {}
                    this.expTypeTemp = Object.assign({}, this.expType)
                    
                    break
                case 'edit':
                    this.toggleDialog()
                    this.expType = expType
                    this.expTypeTemp = Object.assign({}, expType)
                    
                    break
            }
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },


        saveRecord() {
            this.isLoading = true
            // console.log("ExpType", this.expTypeTemp)
            if (this.expTypeTemp.id) {
                this.updateRecord()
            } else {
                this.addRecord()
            }
            
            //
        },

        updateRecord() {

            const exist = this.expenseTypes.find(type => type.name.toLowerCase() === this.expTypeTemp.name.toLowerCase());

            if (exist && exist.id != this.expTypeTemp.id) {
                this.$buefy.dialog.alert({
                    title: 'Already Exist!',
                    message: 'Another Expense Type <span class="has-text-info-dark has-text-weight-bold">' + this.expTypeTemp.name + '</span> already exist!',
                    confirmText: 'OK'
                })
            } else {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                        + '/expense_type'

                axios.put(url, 
                    this.expTypeTemp,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },
                })
                .then( () => {
                    this.fetchRecords()
                })
                .catch ( (err) => {
                    console.log(err.response.status)
                })
                this.isLoading = false                  

            }
        },

        addRecord() {

            const exist = this.expenseTypes.find(type => type.name.toLowerCase() === this.expTypeTemp.name.toLowerCase());

            if (exist) {
                this.$buefy.dialog.alert({
                    title: 'Already Exist!',
                    message: 'Expense Type <span class="has-text-info-dark has-text-weight-bold">' + this.expTypeTemp.name + '</span> already exist!',
                    confirmText: 'OK'
                })
            } else {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/expense_type'
                axios.post(url, 
                    this.expTypeTemp,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },
                })
                .then( () => {
                    this.fetchRecords()
                })
                .catch ( (err) => {
                    console.log(err.response.status)
                })
            }


            this.isLoading = false                  
        },              

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },
    },

    mounted() {
        this.user = this.getUser()
        if (this.getActiveStore.role != "STAFF") {
            this.fetchRecords()
        }
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>